import axios from "axios";
import { ProgressBar } from "primereact/progressbar";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Common/Header";
import HomeSidebar from "../Common/HomeSidebar";
import CommonValues from "../Common/Utils";

export default function UploadPage() {
  const [loading, setLoading] = useState(false);
  const [processFileLoading, setProcessFileLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [fileNames, setFileNames] = useState<any>([]);
  const [fileResponse, setFileResponse] = useState<any>([]);
  const [showIframe, setShowIframe] = useState(false);
  const [fileError, setFileError] = useState("");
  const [progressLoader, setProgressLoader] = useState(0);
  const toast: any = useRef("");
  const [fileCount, setFileCount] = useState(0);
  var chatBotEmbeded = localStorage.getItem("Embeded") === "true";
  const navigate = useNavigate();

  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
  }, []);
  const onFileSelectHandler = (e: any) => {
    setProgressLoader(0);
    const files = e.target.files ? Array.from(e.target.files) : [];
    if (files.length > 0) {
      const fileNamesArray = files.map((file: any) => file.name);

      setFileNames(fileNamesArray);
      setSelectedFiles(files);
      setFileError("");
    } else {
      setFileNames([]);
      setFileError("No files selected");
      setSelectedFiles([]);
    }
  };
  const checkValidation = () => {
    let returnValue = true;
    setFileError("");

    if (selectedFiles == null || selectedFiles.length == 0) {
      setFileError("Please select at least one file to upload.");
      returnValue = false;
    }
    return returnValue;
  };
  const onSubmitFile = async () => {
    if (checkValidation()) {
      setProcessFileLoading(true);

      let LoadingPercent = 100 / selectedFiles.length;
      try {
        const uploadPromises = selectedFiles.map((file: any, index: number) =>
          uploadFile(file, LoadingPercent, index)
        );
        await Promise.all(uploadPromises);
        setShowIframe(true);
      } catch (error) {
        console.error("One or more files failed to upload.", error);
      } finally {
        setProcessFileLoading(false);
      }
    }
  };
  const uploadFile = (
    file: File,
    loadingPercent: any,
    fileNumber: any
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      const FormData = require("form-data");
      const data = new FormData();
      data.append("formFile", file);

      const token = CommonValues.GetToken();
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_NcSupport_Url}/api/ncsupportbot/upload`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          setProgressLoader((prevProgressLoader) =>
            Math.round(prevProgressLoader + loadingPercent)
          );
          setFileCount(fileNumber);
          toast.current.show({
            severity: "success",
            detail: `File upload successful: ${file.name}`,
            life: 3000,
          });
          resolve(response.data);
        })
        .catch((error: any) => {
          if (error.response != null) {
            if (error.response.status === 401) {
              CommonValues.Logout(navigate);
            } else {
              toast.current.show({
                severity: "error",
                detail: `File upload failed: ${file.name}`,
                life: 3000,
              });
            }
          }

          setProgressLoader((prevProgressLoader) =>
            Math.round(prevProgressLoader + loadingPercent)
          );
          reject(error);
          setFileNames([]);
          setSelectedFiles([]);
        });
    });
  };
  const onBackButton = () => {
    setShowIframe(false);
    setFileNames([]);
    setSelectedFiles([]);
    setProgressLoader(0);
    setFileCount(0);
  };
  const onCloseIconClick = (fileName: any) => {
    const updatedSelectedFiles = selectedFiles.filter(
      (file: any) => file.name !== fileName
    );
    setSelectedFiles(updatedSelectedFiles);

    const fileNamesArray = updatedSelectedFiles.map((file: any) => file.name);
    setFileNames(fileNamesArray);
  };
  return (
    <div className="upload-page">
      <Toast ref={toast} />
      <div style={{ minHeight: "6.4vh" }}>
        <Header chatBotEmbeded={chatBotEmbeded} />
      </div>
      <div style={{ minHeight: "93.6vh" }}>
        <div className="row mx-auto nc-chat-bot-main-div-embeded">
          {!chatBotEmbeded && (
            <div className="support-bot-sidebar-main-div">
              <HomeSidebar />
            </div>
          )}
          <div className="col-lg-11 col-md-11 col-sm-11 col-11 d-flex px-0 flex-column">
            <div className="row nc-chat-bot-main-div-embeded">
              <div className="col-lg-2 col-md-2 col-sm-2 col-2"></div>
              <div className="col-lg-8 col-md-8 col-sm-8 col-8 mt-5">
                {showIframe ? null : (
                  <div className="row mx-auto mb-2 ">
                    <div className="col-12 text-center">
                      <h5 className={`mt-2 ${chatBotEmbeded ? "text-14" : ""}`}>
                        <b>
                          Upload the documents you would like to use for
                          training
                        </b>
                      </h5>{" "}
                    </div>
                  </div>
                )}

                <div className="row upload-box position-relative mx-auto p-2">
                  {processFileLoading ? (
                    <div className="d-grid align-items-center">
                      <span>
                        {fileCount} of {selectedFiles.length}
                        <ProgressBar value={progressLoader}></ProgressBar>
                        &nbsp; Uploading....
                      </span>
                    </div>
                  ) : (
                    <>
                      {showIframe ? (
                        <>
                          <div className="col-12 text-start back-btn-position">
                            <input
                              className="btn btn-outline-info "
                              type="button"
                              value="Back"
                              onClick={onBackButton}
                            />
                          </div>

                          <div className="col-12  success-message-box w-75 mx-auto">
                            <h5 className="success-message">
                              All the files you submitted are successfully
                              uploaded.{" "}
                            </h5>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="d-flex justify-content-center align-items-center mb-2">
                            <div className="file-upload">
                              <input
                                type="file"
                                className="custom-file-input"
                                id="fileInput"
                                onChange={onFileSelectHandler}
                                name="fileData"
                                accept=".pdf"
                                multiple
                              />
                              <label
                                htmlFor="fileInput"
                                className={`custom-file-label ${
                                  chatBotEmbeded ? "text-14" : ""
                                }`}
                              >
                                Choose Files
                              </label>
                            </div>

                            <span className="text-danger text-12 ">
                              {fileError}
                            </span>
                          </div>
                          {fileNames.length > 0 ? (
                            <div className="file-name-area">
                              {fileNames.map((fileName: any, index: number) => (
                                <span
                                  key={index}
                                  className={`file-name ${
                                    chatBotEmbeded ? "text-14" : ""
                                  }`}
                                >
                                  {fileName}{" "}
                                  <i
                                    className="fa fa-close ms-2 show-cursor "
                                    aria-hidden="true"
                                    title="Close"
                                    onClick={() => onCloseIconClick(fileName)}
                                  ></i>
                                </span>
                              ))}{" "}
                            </div>
                          ) : null}
                          {selectedFiles.length > 0 ? (
                            <>
                              <div className="col-12 upload-btn">
                                <button
                                  title="Submit your files"
                                  onClick={onSubmitFile}
                                  className="btn submit-btn"
                                >
                                  <div
                                    className={`${
                                      chatBotEmbeded ? "text-14" : ""
                                    }`}
                                  >
                                    Submit
                                  </div>
                                </button>
                              </div>
                            </>
                          ) : null}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 col-2"></div>
            </div>
          </div>
          {chatBotEmbeded && (
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 d-flex px-0">
              <div className="chat-bot-sidebar-main-div-embeded">
                <HomeSidebar chatBotEmbeded={chatBotEmbeded} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
