import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import nowcertsIcon from "../Assets/Images/nc.png";
import Header from "../Common/Header";
export default function Login() {
  useEffect(() => {}, []);
  const onLoginWithNowcerts = () => {
    window.open(process.env.REACT_APP_Nowcerts_Url, "_self");
  };
  return (
    <div className="support-login-page">
      <Header />
      <div className="content-wrapper container-fluid  d-flex justify-content-center">
        <div className=" main-content d-flex align-items-center">
          <div className="row login-box mx-auto">
            <div className="col-12 text-center">
              <h4 className="">Login</h4>

              <div
                className=" authenticating-block text-center p-3"
                style={{ minHeight: "200px" }}
              >
                <span className="continue-btn">
                  {" "}
                  <img
                    src={nowcertsIcon}
                    alt=""
                    height={30}
                  />
                  <input
                    className="continue-btn-input"
                    type="button"
                    value="Continue with NowCerts"
                    onClick={onLoginWithNowcerts}
                  ></input>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
